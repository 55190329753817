import { Chart } from "react-google-charts";

const Visual: React.FC<any> = ({ chart }) => {
	return (
		<div className={chart.styles.layout}>
			{chart.heading && <p className={chart.styles.heading}>{chart.heading}</p>}
			<Chart
				chartType={chart.type}
				data={chart.data}
				options={chart.options}
				width={chart.width}
				height={chart.height}
			/>
		</div>
	);
}

export default Visual;
