export const privateKey = "TReX_JVFPCUS7WR";
export const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://result.trexed.com/api' : 'http://localhost:4000/api';
export const exam = "TrexRound2";
export const colors = {
    red: "DC3910",
    orange: "EE9B22",
    green: "119616",
    blue: "3366CC",
    pink: "FF42A1"
}
export const fontSize = {
    pdfTable: '0.682rem',
    xl: '1.25rem',
    lg: '1.125rem',
    base: '0.950rem',
    sm: '0.870rem',
    xs: '0.820rem',
}
export const lineHeight = '1rem'
export const letterSpacing = '0.025rem'
export const fontWeight = {
    bold: 700,
    base: 500,
    light: 300,
}