import useLogout from '../../../utils/useLogout';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser } from '../../../features/auth/authSlice';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toggleSidebarState, selectSidebarState } from '../../../features/sidebar/sideBarSlice';

const NavBar = () => {
	const dispatch = useDispatch();
	const handleLogout = useLogout();
	const user = useSelector(selectCurrentUser);
	const isSidebarOpen = useSelector(selectSidebarState);
	const logo = require('../../../../assets/images/trex_24_logo.png');
	const handleToggleMenu = () => {
		if (!isSidebarOpen) {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}
		dispatch(toggleSidebarState());
	}
	return (
		<nav className="navbar">
			<div className="navbar-logo">
				<div className="md:hidden">
					<IconButton
						edge="start"
						color="inherit"
						aria-label="menu"
						onClick={handleToggleMenu}
					>
						{isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
					</IconButton>

				</div>
				<img src={logo} alt='logo' className='navbar-brand' />
			</div>
			<div className="welcome-text">Welcome, {user?.name}</div>
			<button className="logout-button" onClick={handleLogout}>
				Logout
			</button>
		</nav>
	)
}

export default NavBar
