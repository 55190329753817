import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';

const Table: React.FC<any> = ({ tableData }) => {
  const columns: any = useMemo<MRT_ColumnDef<any>[]>(() => {
    return tableData?.columns.map((x: any) => {
      return { ...x }
    })
  }, [tableData.columns]);
  const table = useMaterialReactTable<any>({
    columns,
    data: tableData.data,
    ...tableData.options,
  });
  return (
    <div className={tableData.styles.layout}>
      {tableData.heading && <p className={tableData.styles.heading}>{tableData.heading}</p>}
      <MaterialReactTable table={table} />
    </div>
  )
};

export default Table;

