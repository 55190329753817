import { Role } from "../shared/enums";

export interface SidebarConfig {
  [key: string]: {
    label: string;
    route: string;
  };
}

export interface SidebarConfigMap {
  [key: string]: SidebarConfig;
}

const useLayoutConfig = () => {
  const sidebarConfig: Record<Role, SidebarConfig> = {
    STUDENT: {
      result: { label: 'Result', route: '/student/dashboard' },
      help: { label: 'Help', route: '/student/help' },
    },
    SCHOOL: {
      students: { label: 'Students', route: '/school/dashboard' },
      // schoolreport: { label: 'School Report', route: '/school/dashboard' },
      help: { label: 'Help', route: '/school/help' },
    },
    ADMIN: {

    }
  };
  return sidebarConfig;
};

export default useLayoutConfig;

