import React from 'react';
import AppForm from '../../shared/ui-components/appForm/AppForm';
import useFormConfig from '../../config/useFormConfig';
import { Role } from '../enums';
import '../styles/styles.css';
import { useHelpMutation } from '../../features/result/resultApiSlice';

interface HelpProps {
  role: Role;
  containerStyle?: string;
}

const Help: React.FC<HelpProps> = ({ role, containerStyle }) => {
  const { studentHelpFormConfig, schoolHelpFormConfig } = useFormConfig();
  const [help] = useHelpMutation();
  const [successMessage, setSuccessMessage] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const getFormConfig = () => {
    switch (role) {
      case Role.STUDENT:
        return studentHelpFormConfig;
      case Role.SCHOOL:
        return schoolHelpFormConfig;
      default:
        return null;
    }
  };

  const formConfig = getFormConfig();

  if (!formConfig) {
    return (
      <div>
        <p>Invalid role </p>
      </div>
    );
  }

  const handleSubmit = async (formData: Record<string, string>) => {
    const res = await help(formData).unwrap();
    if (res) {
      setSuccessMessage('Your message has been sent successfully, we will get back to you soon.')
    } else {
      setErrorMessage('Something went wrong, please try again later.')
    }
  };

  return (
    <div className={`help-section ${containerStyle}`}>
      <h1 className='help-heading'>Contact Us</h1>
      <p className='help-subheading'>Please fill the form below to contact us.</p>
      <AppForm formConfig={formConfig} onSubmit={handleSubmit} />
      {successMessage && <p className='help-success'>{successMessage}</p>}
      {errorMessage && <p className='help-error'>{errorMessage}</p>}
    </div>
  );
};

export default Help;
