import React, { useEffect, useState } from "react";
import { Role } from "../shared/enums";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useRefreshMutation } from "../features/auth/authApiSlice";
import { setCredentials } from "../features/auth/authSlice";

interface RequireAuthProps {
  role: Role;
}

const RequireAuth = ({ role }: RequireAuthProps) => {
  const auth = useSelector((state: any) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const [refresh] = useRefreshMutation();
  const [isRefreshing, setIsRefreshing] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const refreshToken = async () => {
      try {
        const res = await refresh({}).unwrap();
        dispatch(setCredentials(res));
        setIsRefreshing(false)
      } catch (error) {
        setIsRefreshing(false)
      }
    }
    const data = JSON.parse(localStorage.getItem("data"))
    if (data?.rollNo && auth.user?.id == null) {
      dispatch(setCredentials({ user: { id: data.rollNo, role: Role.STUDENT, name: data.name }, accessToken: "" }));
      setIsRefreshing(false)
    } else if (!auth.accessToken && !data?.rollNo) {
      refreshToken();
    } else {
      setIsRefreshing(false)
    }
    return () => {
      isMounted = false;
    };
  }, [auth, dispatch, refresh]);

  return (
    <>
      {isRefreshing ?
        <p>Loading</p>
        : auth.user?.role === role ? (
          <Outlet />
        ) : (
          <Navigate to="/" state={{ from: location }} replace />
        )}
    </>
  );
};

export default RequireAuth;