import { apiSlice } from "../../../app/api/apiSlice";
import { handleResponse } from "../../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: { ...credentials },
      }),
      transformResponse: (rawData: any) => {
        return handleResponse(rawData);
      }
    }),
    refresh: builder.mutation({
      query: () => ({
        url: '/auth/refreshToken',
        method: 'POST',
      })
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      })
    })
  }),
})

export const {
  useLoginMutation,
  useRefreshMutation,
  useLogoutMutation
} = authApiSlice



