import { createSlice } from '@reduxjs/toolkit';

interface SidebarState {
  isSidebarOpen: boolean;
}

const initialState: SidebarState = {
  isSidebarOpen: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebarState: (state) => {
        state.isSidebarOpen = !state.isSidebarOpen;
    },
  },
});

export const { toggleSidebarState } = sidebarSlice.actions;
export default sidebarSlice.reducer;
export const selectSidebarState = (state: { sidebar: SidebarState }) => state.sidebar.isSidebarOpen;
