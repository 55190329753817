import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./api/apiSlice"
import authReducer from './features/auth/authSlice'
import sidebarReducer from './features/sidebar/sideBarSlice'
import resultModalReducer from './features/school/schoolSlice'

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        sidebar: sidebarReducer,
        resultModal: resultModalReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
})