import { type MRT_RowData, type MRT_TableOptions } from 'material-react-table';
import { fontSize } from '../../utils/constants';

const fontSpec = {
	xs: fontSize.xs,
	sm: fontSize.sm,
	md: fontSize.base,
}
export const getDefaultResultOptions = <TData extends MRT_RowData>(): Partial<
	MRT_TableOptions<TData>
> => ({
	enableGlobalFilter: false,
	enableRowPinning: false,
	initialState: { showColumnFilters: false },
	manualFiltering: false,
	manualSorting: false,
	muiTableHeadCellProps: {
		sx: {
			fontSize: fontSpec,
			fontWeight: 'bold',
		},
	},
	muiTableBodyCellProps: {
		sx: {
			fontSize: fontSpec,	
		},
	},
	enablePagination: false,
	enableBottomToolbar: false,
	enableTopToolbar: false,
	enableSorting: false,
	enableColumnFilters: false,
	enableColumnActions: false,
});

