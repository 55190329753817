import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../features/auth/authSlice';
import { AuthState } from '../features/auth/authSlice';
import { BASE_URL } from '../utils/constants';

interface BaseQueryArgs {
  url: string;
  method: string;
}

export const handleResponse = (data: any, transformData?: (data: any) => any) => {
  if (data.error) {
    throw new ApiError(data.error, data.status);
  } else if (transformData === undefined) {
    return data;
  } else {
    return transformData(data);
  }
}

export class ApiError extends Error {
  constructor(message: string, public status: number, public response?: any) {
    super(message);
    this.name = 'ApiError';
    this.status = status;
  }
}

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as { auth: { accessToken: string } }).auth.accessToken;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    return headers;
  },
  responseHandler: async (response: Response) => {
    try {
      const data = await response.json();
      if (response.ok && data.success) {
        return data.data;
      }
      return { error: data.reason, status: response.status }
    } catch (error) {
      console.error(error);
    }
  },
});

const baseQueryWithReauth = async (args: BaseQueryArgs, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    const refreshResult = await baseQuery({ url: '/auth/refreshToken', method: 'POST' }, api, extraOptions);
    if (refreshResult?.data) {
      api.dispatch(setCredentials(refreshResult.data as AuthState));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
