export enum Role {
    STUDENT = 'STUDENT',
    SCHOOL = 'SCHOOL',
    ADMIN = 'ADMIN',
}

export enum FieldType {
    TEXT = 'text',
    PASSWORD = 'password',
    DATE = 'date',
    CHECKBOX = 'checkbox',
    EMAIL = 'email',
    TEL = 'tel',
    TEXTAREA = 'textarea',
}
