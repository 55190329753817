import { createSlice } from '@reduxjs/toolkit';

export const ROLLNO = "Roll No";

interface ResultModalState {
  isModalOpen: boolean;
  "Roll No": string;
  exam: string;
}

const initialState: ResultModalState = {
  isModalOpen: false,
  "Roll No": "",
  exam: "",
};

const schoolSlice = createSlice({
  name: 'resultModal',
  initialState,
  reducers: {
    toggleResultModalState: (state) => {
      state.isModalOpen = !state.isModalOpen;
    },
    setResultModalCredentials: (state, action) => {
      state[ROLLNO] = action.payload[ROLLNO];
      state.exam = action.payload.exam;
    }
  },
});

export const { toggleResultModalState, setResultModalCredentials } = schoolSlice.actions;
export default schoolSlice.reducer;
export const selectResultModalState = (state: { resultModal: ResultModalState }) => state.resultModal.isModalOpen;
export const selectResultModalCredentials = (state: { resultModal: ResultModalState }) => state.resultModal;
