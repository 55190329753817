import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Role } from '../enums';
import './styles.css';
import SideBar from './components/SideBar';
import NavBar from './components/NavBar';
import { selectSidebarState, toggleSidebarState } from '../../features/sidebar/sideBarSlice';
import { useSelector, useDispatch } from 'react-redux';

interface LayoutProps {
  role: Role;
}

const Layout: React.FC<LayoutProps> = ({ role }) => {
  const isSidebarOpen = useSelector(selectSidebarState);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleBodyClick = () => {
      if (isSidebarOpen) {
        dispatch(toggleSidebarState());
      }
    };

    const main = document.getElementById('main');
    main?.addEventListener('click', handleBodyClick);

    return () => {
      main?.removeEventListener('click', handleBodyClick);
    };
  }, [isSidebarOpen, dispatch]);

  return (
    <div className="main-section">
      <NavBar />
      <div className="content">
        <SideBar role={role} />
        <main className="main-content" id="main">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
