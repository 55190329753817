import React, { useState, useEffect, useCallback } from 'react';
import { FormConfig, FormField } from '../../../config/useFormConfig';
import './styles.css';

interface AppFormProps {
	formConfig: FormConfig;
	onSubmit: (formData: Record<string, string>) => void;
	isLoading?: boolean;
	onHelpClick?: () => void;
}

const AppForm: React.FC<AppFormProps> = ({ formConfig, onSubmit, isLoading, onHelpClick }) => {
	const [formData, setFormData] = useState<Record<string, string>>({});
	const [errors, setErrors] = useState<Record<string, string>>({});
	const sortedFields = formConfig.fields.slice().sort((a: FormField, b: FormField) => a.order - b.order);

	useEffect(() => {
		initializeForm();
	}, [formConfig]);

	const initializeForm = useCallback(() => {
		const initialFormValues = sortedFields.reduce(
			(acc, field) => ({ ...acc, [field.name]: field.value || '' }),
			{}
		);
		setFormData(initialFormValues);
	}, [sortedFields]);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setErrors({});
			const { name, value, type, checked } = e.target;
			const field = formConfig.fields.find((field) => field.name === name);

			if (field) {
				const fieldValue = type === 'checkbox' ? checked.toString() : value;
				setFormData((prevData) => ({ ...prevData, [name]: fieldValue }));
			}
		},
		[formConfig]
	);

	const handleSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			try {
				const fieldErrors: Record<string, string> = {};
				formConfig.fields.forEach((field) => {
					const { name } = field;
					const value = formData[name];
					if (field.validator) {
						const error = field.validator(value);
						if (error) {
							fieldErrors[name] = error;
						}
					}
				});
				setErrors(fieldErrors);
				if (Object.keys(fieldErrors).length === 0 && onSubmit) {
					await onSubmit(formData);
				}
			} catch (err) {
				console.error(err);
			} finally {
				initializeForm();
			}
		},
		[formData, onSubmit, formConfig.fields, initializeForm]
	);

	const renderInput = (field: FormField) => {
		const inputProps: FormField = {
			type: field.type,
			name: field.name,
			id: field.name,
			placeholder: field.placeholder,
			className: 'input-field',
			disabled: field.disabled || isLoading,
			required: field.required,
			rows: field.rows,
		} as const;

		if (field.type === 'checkbox') {
			inputProps.checked = formData[field.name] === 'true';
			inputProps.onChange = handleChange as React.ChangeEventHandler<HTMLInputElement>;
		} else {
			inputProps.value = formData[field.name] || '';
			inputProps.onChange = handleChange as React.ChangeEventHandler<HTMLInputElement>;
		}

		return (
			<div className='input-section'>
				{field.showLabel && <label htmlFor={field.name} className='mr-2'>{field.label}: </label>}
				{field.type === 'textarea' ? (
					<textarea {...inputProps}></textarea>
				) : (
					<input {...inputProps} />
				)}
			</div>
		);
	};

	return (
		<form onSubmit={handleSubmit}>
			{sortedFields.map((field) => (
				<div className="mb-3" key={field.name}>
					{renderInput(field)}
				</div>
			))}
			{Object.keys(errors).length > 0 && Object.keys(errors).map((fieldName) => (
				<p key={fieldName} className="danger-text">
					{errors[fieldName]}
				</p>
			))}
			<div className='form-footer'>
				{formConfig.help && <p className='help-text' onClick={onHelpClick}>{formConfig.help}</p>}
				<button type="submit" className={formConfig.submitBtn?.className} disabled={isLoading}>
					{isLoading ? 'Submitting...' : formConfig.submitBtn?.label}
				</button>
			</div>
		</form>
	);
};

export default AppForm;
