import { useEffect, useState } from "react";
import Result from "../shared/ui-components/Result";
import { privateKey } from "./constants";
import { useParams } from "react-router-dom";

const ResultPDF = () => {
    const params = useParams();
    const [isValidRequest, setIsValidRequest] = useState(false);
    useEffect(() => {
        if (params.privateKey !== privateKey) {
            setIsValidRequest(false);
        }
        const resultElement = document.getElementById("result");
        if (resultElement) {
            resultElement.classList.add("!w-4/5");
        }
        return () => {
            if (resultElement) {
                resultElement.classList.remove("!w-4/5");
            }
        };
    }, [isValidRequest, params.privateKey]);
    return (
        { isValidRequest } ? <Result exam={params.exam} rollNo={params.rollNo} showTabs={false} /> : <p>Invalid Request</p>
    )
};

export default ResultPDF;