import { apiSlice } from "../../api/apiSlice";
import { transformSummaryResponse, transformSubjectsResponse, transformTopicsResponse, transformDifficultyLevelsResponse } from "./services";
import { handleResponse } from "../../api/apiSlice";

export const resultApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        resultSummary: builder.query({
            query: (credentials) => ({
                url: `/result/getResultSummary/${credentials.exam}/${credentials.rollNo}`,
                method: 'GET',
            }),
            transformResponse: (rawData) => {
                return handleResponse(rawData, transformSummaryResponse);
            },
        }),
        subjects: builder.query({
            query: (credentials) => ({
                url: `/result/getSubjectWiseResult/${credentials.exam}/${credentials.rollNo}`,
                method: 'GET',
            }),
            transformResponse: (rawData) => {
                return handleResponse(rawData, transformSubjectsResponse);
            },
        }),
        topics: builder.query({
            query: (credentials) => ({
                url: `/result/getTopicWiseResult/${credentials.exam}/${credentials.rollNo}`,
                method: 'GET',
            }),
            transformResponse: (rawData) => {
                return handleResponse(rawData, transformTopicsResponse);
            },
        }),
        difficultyLevels: builder.query({
            query: (credentials) => ({
                url: `/result/getDifficultyWiseResult/${credentials.exam}/${credentials.rollNo}`,
                method: 'GET',
            }),
            transformResponse: (rawData) => {
                return handleResponse(rawData, transformDifficultyLevelsResponse);
            },
        }),
        help: builder.mutation({
            query: (credentials) => ({
                url: `/support/help`,
                method: 'POST',
                body: credentials,
            }),
        }),
    }),
});

export const {
    useResultSummaryQuery,
    useSubjectsQuery,
    useTopicsQuery,
    useDifficultyLevelsQuery,
    useHelpMutation,
} = resultApiSlice



