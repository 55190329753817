import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Layout from './shared/layout/Layout';
import RequireAuth from './utils/RequireAuth';
import { Role } from './shared/enums';
import SchoolDashboard from './pages/school/Dashboard';
import Dashboard from './pages/student/Dashboard';
import Help from './shared/ui-components/Help';
import ResultPDF from './utils/ResultPDF'

function App() {
  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route element={<RequireAuth role={Role.STUDENT} />} >
        <Route path="/student/" element={<Layout role={Role.STUDENT} />} >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="help" element={<Help role={Role.STUDENT} />} />
        </Route>
      </Route>
      <Route element={<RequireAuth role={Role.SCHOOL} />} >
        <Route path="school/" element={<Layout role={Role.SCHOOL} />} >
          <Route path="dashboard" element={<SchoolDashboard />} />
          <Route path="help" element={<Help role={Role.SCHOOL} />} />
        </Route>
      </Route>
      <Route path="/result/:privateKey/:rollNo/:exam" element={<ResultPDF />} />
    </Routes>
  );
}

export default App;
