import { useLogoutMutation } from "../features/auth/authApiSlice";
import { logOut } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const useLogout = () => {
    const [logout] = useLogoutMutation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handlelogout = async () => {
        await logout({}).unwrap;
        dispatch(logOut());
        navigate("/");
    }
    return handlelogout;
}

export default useLogout;