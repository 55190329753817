import { useState, useEffect } from 'react';
import Result from '../../shared/ui-components/Result';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/auth/authSlice';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Certificate from './Certificate';
import { useResultSummaryQuery } from '../../features/result/resultApiSlice';
import { BASE_URL, exam } from '../../utils/constants'

const Dashboard = () => {
  const downloadFlag = false;
  const alertMessage = 'You will be able to download the certificate soon.';
  const alertTimeout = 6000;
  const [showAlert, setShowAlert] = useState(false);
  const user = useSelector(selectCurrentUser);
  const rollNo = user?.id;
  const [isDownloading, setIsDownloading] = useState(false);
  const { data, error, isLoading } = useResultSummaryQuery({ exam, rollNo, });
  const handleDownload = () => {
    if (downloadFlag) {
      setIsDownloading(true);
      fetch(`${BASE_URL}/result/getResultPDF/${exam}/${rollNo}`, {
        method: 'GET',
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${exam}.pdf`;
          link.click();
        })
        .catch((error) => {
          console.error('Error downloading PDF:', error);
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, alertTimeout);
  };

  useEffect(() => {
    setShowAlert(false);
  }, []);

  return (
    <>
      <div className="flex-center">
        {!error && !isLoading &&
          <>
            
            <p className='top-text'><p><a href='https://www.trexed.com/trex-tricity-2024-partners/'><button className='submit-btn mb-3 text'>
            Explore TReX Partner's Programs & Scholarships
            </button>
            </a></p>Prize distribution details will be shared soon via your school and registered phone number.</p>
          </>
        }
      </div>
      {showAlert && <p className='danger-text p-2'>{alertMessage}</p>}
      <Result rollNo={rollNo} exam={exam} />
    </>
  );
};

export default Dashboard;
