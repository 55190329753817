import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';

interface GenericDropdownProps<T> {
  label: string;
  options: T[];
  value: T;
  onChange: (event: SelectChangeEvent<T>) => void;
}

const Dropdown: React.FC<GenericDropdownProps<string | number>> = ({
  label,
  options,
  value,
  onChange,
}) => {
  const controlId = label.toLowerCase();
  return (
    <FormControl>
      <InputLabel htmlFor={controlId}>{label}</InputLabel>
      <Select
        labelId={controlId}
        id={controlId}
        value={value}
        onChange={onChange}
        label={label}
        name={controlId}
      >
        {options.map((option, index) => (
          <MenuItem key={option} value={index}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
