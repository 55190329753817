import { SummaryInterface } from "./resultInterface"
import { getAttributeScreenOptimized } from "../../utils/screenOptimize";
import { colors } from "../../utils/constants";
//styles for table and chart can be modified in /src/app/shared/styles.css
const tableStyles = { layout: "table-layout", heading: "heading" };
const subTableStyles = { layout: "table-layout", heading: "heading" };
const chartStyles = { layout: "result-chart", heading: "heading"}
export const transformSummaryResponse = (data: any): SummaryInterface => {
    const transformedData: SummaryInterface = {
        Name: data.name,
        Class: data.class,
        'Roll No': data.rollNo,
        DOB: data.dob,
        School: data.school,
        Score: data.score,
        Total: data.total,
        Rank: data.rank,
    }
    return transformedData;
}

export const transformSubjectsResponse = (res: any): any => {
    const data: any[] = res.result;
    data.sort((a, b) => a.order - b.order);
    const subjects = data.map((item) => {
        return {
            Subject: item.subject,
            'Your Marks': item.score,
            'Average Marks': item.average,
            'Your Percentage': item.percentage,
            'Maximum Marks': item.maximum,
            'Highest Marks': item.highest,
        }
    })
    const columns = subjects.length > 0
        ? Object.keys(subjects[0])
            .map((key) => ({ accessorKey: key, header: key, size: 30, }))
        : [];

    const chartData: any[] = [["Marks", "Your Marks", "Average Marks", "Highest Marks", "Maximum Marks"]]
    subjects.forEach((item) => {
        chartData.push([item.Subject, item["Your Marks"], item["Average Marks"], item["Highest Marks"], item["Maximum Marks"]]);
    });

    const leftAttribute = getAttributeScreenOptimized(["10%", "7%", "5%"]);
    return {
        table: { columns, data: subjects, heading: "Subject Wise Results", styles: tableStyles },
        chart: {
            heading: "Subject Wise Results Graphical Analysis",
            type: "ColumnChart",
            data: chartData,
            options: {
                legend: { position: 'top', maxLines: 3, },
                titleTextStyle: { fontSize: 20, colortop: "gray" },
                chartArea: { top: "6%", left: leftAttribute, width: '100%', height: '100%', bottom: "8%" },
            },
            width: "100%",
            height: "500px",
            styles: chartStyles
        }
    }
}

export const transformTopicsResponse = (res: any): any => {
    const data: any[] = res.result;
    data.sort((a, b) => a.order - b.order);
    data.forEach((topic) => {
        topic.result.sort((a: any, b: any) => a.order - b.order);
    });
    const topics = data.map((item) => {
        return {
            subject: item.subject,
            result: item.result.map((topic: any) => {
                const Performance = () => {
                    let ratio = topic.correct / topic.total;
                    if (ratio >= 0.75) return "🟢 🟢 🟢";
                    else if (ratio >= 0.5) return "🟠 🟠";
                    else return "🔴";
                }
                return {
                    Topic: topic.topic,
                    Correct: topic.correct,
                    Incorrect: topic.incorrect,
                    Unattempted: topic.unattempted,
                    Total: topic.total,
                    Performance: Performance(),
                }
            }),
        }
    })
    const columns = topics.length > 0
        ? Object.keys(topics[0].result[0])
            .map((key) => ({ accessorKey: key, header: key, size: (key === "Topic") ? 80 : 30, }))
        : [];
    const topicsData = topics.map((topic) => {
        return {
            subjectName: topic.subject,
            table: { columns, data: topic.result, styles: subTableStyles },
        }
    })
    return topicsData;
}

export const transformDifficultyLevelsResponse = (res: any): any => {
    const data: any[] = res.result;
    data.sort((a, b) => a.order - b.order);
    data.forEach((topic) => {
        topic.result.sort((a: any, b: any) => a.order - b.order);
    });
    const transformedData = data.map((item) => {
        return {
            subject: item.subject,
            result: item.result.map((topic: any) => {
                return {
                    Level: topic.level,
                    Correct: topic.correct,
                    Incorrect: topic.incorrect,
                    Unattempted: topic.unattempted,
                    Total: topic.total,
                }
            }),
        }
    })
    const columns = transformedData.length > 0
        ? Object.keys(transformedData[0].result[0])
            .map((key) => ({ accessorKey: key, header: key, size: 30, }))
        : [];
    const difficultyData = transformedData.map((topic) => {
        const height = getAttributeScreenOptimized(["300px", "400px", "400px"]);
        const chartData = [["Difficulty Level", "Correct"]];
        topic.result.forEach((item: any) => {
            !(item.Level === "Total") && chartData.push([item.Level, item.Correct]);
        });
        const sliceColors = {
            hard: colors.red,
            moderate: colors.orange,
            easy: colors.green,
        }
        return {
            subjectName: topic.subject,
            table: { columns, data: topic.result, styles: subTableStyles },
            chart: {
                heading: "Difficulty Level Wise Results Graphical Analysis",
                type: "PieChart",
                data: chartData,
                options: {
                    pieSliceText: 'value-and-percentage',
                    titleTextStyle: { fontSize: 20, color: "gray" },
                    is3D: true,
                    chartArea: { top: "6%", width: '100%', height: '100%', bottom: "8%" },
                    // slices: [
                    //     { color: sliceColors.hard },
                    //     { color: sliceColors.moderate },
                    //     { color: sliceColors.easy },
                    // ],
                    legend: { position: 'top', maxLines: 3 },
                },
                width: "100%",
                height,
                styles: chartStyles
            }
        }
    })
    return difficultyData;
}