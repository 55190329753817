import { createSlice, PayloadAction  } from "@reduxjs/toolkit";
import { Role } from "../../shared/enums";

export interface AuthState {
  user: User | null;
  accessToken: string | null;
}

export interface User {
  id: string;
  role: Role;
  name: string;
}

const initialState: AuthState = {
  user: null,
  accessToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<{ user: User; accessToken: string }>) => {
      const { user, accessToken } = action.payload;
      return {
        ...state,
        user,
        accessToken,
      };
    },    
    logOut: (state) => {
      state.user = null;
      state.accessToken = null;
      localStorage.clear();
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentUser = (state: { auth: AuthState }) => state.auth.user;
export const selectCurrentToken = (state: { auth: AuthState }) => state.auth.accessToken;
