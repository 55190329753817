import useLayoutConfig from '../../../config/useLayoutconfig';
import { Role } from '../../enums';
import { useSelector, useDispatch } from 'react-redux';
import { selectSidebarState } from '../../../features/sidebar/sideBarSlice';
import { NavLink } from 'react-router-dom';
import { toggleSidebarState } from '../../../features/sidebar/sideBarSlice';
interface SideBarProps {
	role: Role
}

const SideBar = (props: SideBarProps) => {
	const sidebarConfig = useLayoutConfig();
	const sidebarItems = sidebarConfig[props.role];
	const isSidebarOpen = useSelector(selectSidebarState);
	const dispatch = useDispatch();
	const handleNavLinkClick = () => {
		dispatch(toggleSidebarState());
	};

	return (
		<aside className={`sidebar ${isSidebarOpen ? 'open' : 'close'}`}>
			<ul className="sidebar-list">
				{Object.keys(sidebarItems).map((itemKey) => {
					const item = sidebarItems[itemKey];
					return (
						<li key={itemKey} onClick={handleNavLinkClick}>
							<NavLink to={item.route} className="sidebar-item">
								{item.label}
							</NavLink>
						</li>
					);
				})}
			</ul>
		</aside>
	)
}

export default SideBar
