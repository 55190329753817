import React, { ReactNode } from 'react';
import { Modal } from '@mui/material';

interface GenericModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const GenericModal: React.FC<GenericModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <>
          <div className='modal-background'></div>
          <Modal open={isOpen} onClose={onClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className='modal-container'>
              <button className='modal-close' onClick={onClose}>
                <svg className='fixed w-6 h-6' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                </svg>
              </button>
              {children}
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default GenericModal;
